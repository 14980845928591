.War {
  box-shadow: 5px;
  border-radius: 10px;
  padding: 20px;
  width: 45em;
  margin: 10px;
  background-color: rgba(11, 22, 82, 0.35);
}

.progress {
  min-width: 100%;
}

h3 {
  font-size: 1em;
  font-weight: bold;

}

.event-box {
  text-align: center;
  box-shadow: 20px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.31)
}

.good {
  background-color: rgba(0, 221, 48, 0.31)
}

.bad {
  background-color: rgba(218, 2, 2, 0.51)
}

.neutral {
  background-color: rgba(216, 222, 183, 0.51)
}

.third {
  text-align: center;
  width: 33%;
}

.App {
  text-align: center;
}

.center {
  text-align: center;
}

.bold {
  font-weight: bold;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}